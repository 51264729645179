<template>
  <div id="auto-credit-view">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="AutoCredit">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <div class="vx-row my-4 mx-3" >
      <div class="vx-col">
        <dynamic-customers-drop-down
          :placeholder="$t('SearchCustomer')"
          :selected-item="customer"
          @on-item-selected="onCustomerChanged($event)">
        </dynamic-customers-drop-down>
      </div>
      <div class="vs-col">
        <div style="display: grid;">
          <label
            style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
            <span
              @click="fromDate = null;toDate = null;updateFilter()"
              class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
          </label>
          <date-picker
            :disabled-date="disableFutureDate"
            class="py-1 pt-2" :placeholder="$t('FromDate')"
            v-model="fromDate" type="date"></date-picker>
        </div>
      </div>
      <div class="vx-col">
        <div style="display: grid;">
          <label
            style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
            <span
              @click="fromDate = null;toDate = null;updateFilter()"
              class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
          </label>
          <date-picker
            :disabled-date="disableFromToDate"
            class="py-1 pt-2" :placeholder="$t('ToDate')"
            v-model="toDate" type="date"></date-picker>
        </div>
      </div>
      <div class="vx-col">
        <vs-button class="mt-6" style="margin: auto;"
                   @click="updateFilter()">Filter</vs-button>
      </div>
    </div>
    <div class="vx-row w-full my-2 mx-3">

      <div class="vx-col md:w-1/3" v-if="Object.keys(this.customer).length > 0">
        <vx-card v-if="Object.keys(this.customer).length > 0">
          <div class="vx-col">
            <h3><strong>{{$t('AutoCredit')}}</strong></h3>
          </div>
          <div class="vx-col">
<!--            credit_limit, balance_trigger, balance_top_up, auto_credit-->
            <vs-input class="inputx pt-8 w-full"
                      v-validate="'required|numeric|min_value:0'"
                      validate-on="change"
                      type="number"
                      name="creditlimit"
                      :label="$t('CreditLimit') + '*'"
                      v-model="creditLimit"/>
            <span class="text-danger text-sm">{{ errors.first('creditlimit') }}</span>
            <vs-input class="inputx pt-8 w-full"
                      v-validate="'required|numeric'"
                      validate-on="change"
                      type="number"
                      name="balancetrigger"
                      :label="$t('BalanceTrigger') + '*'"
                      v-model="balanceTrigger"/>
            <span class="text-danger text-sm">{{ errors.first('balancetrigger') }}</span>
            <vs-input class="inputx pt-8 w-full"
                      v-validate="'required|numeric'"
                      validate-on="change"
                      type="number"
                      name="balancetopup"
                      :label="$t('BalanceTopUp') + '*'"
                      v-model="balanceTopUp"/>
            <span class="text-danger text-sm">{{ errors.first('balancetopup') }}</span>

            <div class="vx-col w-full mt-3">

              <div class="vs-row flex py-2">
                <vs-switch color="dark" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="autoCredit">
                  <span slot="on">{{ $t('YES') }}</span>
                  <span slot="off">{{ $t('NO') }}</span>
                </vs-switch>
                <p class="px-3">{{ $t('AutoCredit') }}</p>
              </div>
            </div>

            <div class="mt-6"></div>

            <div class="vx-row">
              <vs-button style="margin: auto;"
                         :disabled="!validateForm"
                         @click="saveAutoCreditDetails()">Save</vs-button>
            </div>

          </div>
        </vx-card>
      </div>
      <div class="vx-col md:w-2/3 px-14" :class="[Object.keys(this.customer).length > 0 ? 'md:w-2/3': 'md:w-full']">
        <!--    Error List State-->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
              <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                   class="mx-auto mb-4 max-w-full">
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
            </div>
          </div>
        </transition>

        <vx-card>
          <vs-tabs alignment="fixed">
            <vs-tab :label="$t('AutoCreditHistory')">
              <div>
                <vs-table stripe  v-show="creditLogs.length > 0 && !errorFetching" ref="table"
                          :data="creditLogs">
                  <template slot="thead">
                    <vs-th >{{ $t('Date') }}</vs-th>
                    <vs-th >{{ $t('Organisation') }}</vs-th>
                    <vs-th >{{ $t('CreditLimit') }}</vs-th>
                    <vs-th >{{ $t('Credit') }}</vs-th>
                    <vs-th >{{ $t('BalanceTrigger') }}</vs-th>
                    <vs-th >{{ $t('BalanceTopUp') }}</vs-th>
                    <vs-th >{{ $t('OldBalance') }}</vs-th>
                    <vs-th >{{ $t('NewBalance') }}</vs-th>
                    <vs-th >{{ $t('Staff') }}</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        <p
                          style="max-width: 100px; word-break: normal !important;"
                          class="font-medium ">
                          {{ tr.updated | dateFormat }}
                        </p>
                      </vs-td>
                      <vs-td>
                        <div class="vs-col">
                          <p class="font-medium truncate">{{ tr.organisation__name }}</p>
                          <p class="font-medium truncate">{{ tr.organisation__customer_id }}</p>
                        </div>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate">{{ tr.credit_limit | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.standard_credit | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.balance_trigger | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.balance_top_up | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.from_balance | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.to_balance | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.staff }}</p>
                      </vs-td>
                    </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </div>
            </vs-tab>
            <vs-tab :label="$t('CreditLedger')">
              <div>

                <vs-table stripe v-show="creditLedger.length > 0" ref="table"
                          :data="creditLedger">
                  <template slot="thead">
                    <vs-th >{{ $t('Date') }}</vs-th>
                    <vs-th >{{ $t('Customer') }}</vs-th>
                    <vs-th >{{ $t('OldCreditLimit') }}</vs-th>
                    <vs-th >{{ $t('CreditLimit') }}</vs-th>
                    <vs-th >{{ $t('OldBalanceTrigger') }}</vs-th>
                    <vs-th >{{ $t('BalanceTrigger') }}</vs-th>
                    <vs-th >{{ $t('OldBalanceTopUp') }}</vs-th>
                    <vs-th >{{ $t('BalanceTopUp') }}</vs-th>
                    <vs-th >{{ $t('AutoCredit') }}</vs-th>
                    <vs-th >{{ $t('User') }}</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <tbody>
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        <p
                          style="max-width: 100px; word-break: normal !important;"
                          class="font-medium">{{ tr.updated | dateFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate">{{ tr.to_organisation }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate">{{ tr.from_credit_limit | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.to_credit_limit | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.from_balance_trigger | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.to_balance_trigger | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.from_balance_top_up | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.to_balance_top_up | germanNumberFormat }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate" >{{ tr.to_is_auto_credit == 0 ? "No" :"Yes" }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="font-medium truncate">{{ tr.created_by }}</p>
                      </vs-td>
                    </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  name: 'AutoCredit',
  data() {
    return {
      fromDate: null,
      toDate: null,
      creditLimit: 0,
      balanceTrigger: 0,
      balanceTopUp: 0,
      autoCredit: true,
      customer: {},
      isMounted: false,
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Customers', i18n: 'Customers', url: '/customers/view' },
        { title: 'Auto Credit', i18n: 'AutoCredit', active: true },
      ],
    }
  },
  components: {
    DatePicker,
    DynamicCustomersDropDown,
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  computed: {

    creditLogs() {
      return this.$store.state.autoCredit.creditLogs
    },

    creditLedger() {
      return this.$store.state.autoCredit.creditLedger
    },
    validateForm() {
      return !this.errors.any() && this.creditLimit >= 0
        && this.balanceTopUp >= 0
        && this.balanceTrigger >= 0
        && Object.keys(this.customer).length > 0
    },
  },
  methods: {
    disableFutureDate(date) {
      return date > moment().subtract()
    },
    disableFromToDate(date) {
      const today = new Date()

      return date > today || date < this.fromDate
    },
    onCustomerChanged(c) {
      this.customer = c

      this.$router.push({ name: 'customer-credit-auto', params: { customerId: this.customer.customer_id } })

      this.fetchCustomerDetails()
      this.fetchCreditLogs()
      this.fetchCreditLedgers()
    },
    updateFilter() {
      this.fetchCreditLogs()
      this.fetchCreditLedgers()
    },
    getFilters() {
      const payload = {}
      if (Object.keys(this.customer).length > 0) {
        payload.customer_id = this.customer.customer_id
      }

      if (this.fromDate) {
        payload.from_date = moment(this.fromDate).format('yyyy-MM-DD')
      }
      if (this.toDate) {
        payload.to_date = moment(this.toDate).format('yyyy-MM-DD')
      }

      return payload
    },
    async saveAutoCreditDetails() {
      if (this.validateForm) {
        const payload = {
          credit_limit: this.creditLimit,
          balance_top_up: this.balanceTopUp,
          balance_trigger: this.balanceTrigger,
          auto_credit: this.autoCredit ? 1 : 0,
          customer_id: this.customer.customer_id,
        }
        this.$vs.loading()
        const data = await this.$store.dispatch('customer/updateAutoCredit', payload).catch((error) => {
          this.$vs.loading.close()
          this.notifyError(error.response.data.message || error.response.statusText)
          return false
        })
        if (data) {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          // await this.$router.push({name: 'customers-view'})

          this.fetchCustomerDetails()
          this.fetchCreditLogs()
          this.fetchCreditLedgers()
          return true
        }
        this.$vs.loading.close()
        this.notifyError('Unknown error')
        return false
      }
      return false
    },
    fetchCustomerDetails() {
      return this.$store.dispatch('customer/fetchCustomerDetails', this.customer.customer_id)
        .then((data) => {
          this.autoCredit = data.auto_credit
          this.creditLimit = data.credit_limit
          this.balanceTrigger = data.balance_trigger
          this.balanceTopUp = data.balance_top_up
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.notifyError(error.response.data.message || error.message)
        })
    },
    fetchCreditLogs() {
      this.$vs.loading()
      this.$store.dispatch('autoCredit/fetchAutoCreditLog', { payload: this.getFilters() }).then((data) => {
        this.$vs.loading.close()
      })
        .catch((error) => {
          this.$vs.loading.close()
          this.notifyError(error.response.data.message)
        })
    },
    fetchCreditLedgers() {
      this.$vs.loading()
      this.$store.dispatch('autoCredit/fetchAutoCreditLedger', { payload: this.getFilters() }).then((data) => {
        this.$vs.loading.close()
      })
        .catch((error) => {
          this.$vs.loading.close()
          this.notifyError(error.response.data.message)
        })
    },
    notifyError(msg) {
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: msg,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
  },
  mounted() {
    if (this.customerId) {
      this.customer = {
        'customer_id': this.customerId,
      }
      this.fetchCustomerDetails()
    }
    this.fetchCreditLogs()
    this.fetchCreditLedgers()
  },
}
</script>

<style scoped>
.vs-tabs--content {
  padding: 10px 0px !important;
}
</style>
